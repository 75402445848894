/* 
 * * Global Variables
 *
 * @designer: Sure Das
 */

$authInputBox: 0px 2px 4px 0px rgba(7, 4, 146, 0.1),
  0px 24px 60px 0px rgba(6, 47, 125, 0.05),
  0px 12px 24px 0px rgba(27, 59, 119, 0.05);
$leftSidebar: 0px 0px 11px 0px rgba(0, 0, 0, 0.13);
$tableHover: 0px 2px 4px 0px rgba(7, 4, 146, 0.1),
  0px 24px 20px 0px rgba(6, 47, 125, 0.05),
  0px 12px 10px 0px rgba(27, 59, 119, 0.05);
$leftSidebarFooter: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
$leftSidebarProfileDetails: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
$leftSidebarHeader: 5px -3px 10px 0px #1f1f1f;
$leftSidebarModal: 0px -8px 11px 0px rgba(0, 0, 0, 0.13);
$globaInputShadow: 0px 2px 4px 0px rgba(7, 4, 146, 0.1),
  0px 24px 60px 0px rgba(6, 47, 125, 0.05),
  0px 12px 24px 0px rgba(27, 59, 119, 0.05);
$adminHeaderShadow: 0px 3px 7px 0px #e9e9e9;
$slideToggleShadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
$postCardShadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
